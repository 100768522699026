<template>
  <div
    class="flex flex-col"
    :class="[
      twMerge(
        content?.background === 'dark'
          ? 'bg-teal-900 pt-10 pb-6 md:py-20'
          : 'mt-10 mb-6 md:my-20'
      ),
    ]"
  >
    <div class="max-w-screen-xl m-auto px-4">
      <h2
        v-if="content?.title"
        class="my-2"
        :class="[twMerge(content?.background === 'dark' && 'text-stone-50')]"
      >
        {{ content.title }}
      </h2>
      <h5 v-if="content?.subtitle" class="my-2">{{ content.subtitle }}</h5>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
        <div
          v-for="item in content.items"
          :key="item?.id"
          class="p-4 rounded-xl"
          :class="[
            twMerge(
              item?.background === 'dark'
                ? 'dark bg-teal-900'
                : 'light bg-stone-50',
              item?.imagePosition === 'right' &&
                'flex flex-col md:flex-row gap-4',
              item?.imagePosition === 'left' &&
                'flex flex-col-reverse md:flex-row-reverse gap-4',
              item?.imagePosition === 'bottom' && 'flex flex-col gap-4',
              item?.imagePosition === 'top' && 'flex flex-col-reverse gap-4',
              item?.imagePosition === 'background' &&
                'image-position-background flex flex-col gap-4',
              item?.rows === 2 && 'md:row-span-2',
              item?.rows === 3 && 'md:row-span-3',
              item?.columns === 2 && 'md:col-span-2',
              item?.columns === 3 && 'md:col-span-3',
              item?.alignment === 'center' && 'flex justify-center items-center'
            ),
          ]"
        >
          <div class="flex flex-col">
            <span
              class="text-sm mb-1.5 block"
              :class="[twMerge(item?.background === 'dark' && 'text-stone-50')]"
              >{{ item?.caption }}</span
            >
            <h4
              :class="[
                item?.alignment === 'center' && 'text-center',
                item?.background === 'dark' && 'text-stone-50',
              ]"
            >
              {{ item?.title }}
            </h4>
            <vue-markdown
              v-if="item?.content"
              class="prose mt-1.5 mb-5 leading-6"
              :class="[
                item?.alignment === 'center' && 'text-center',
                item?.background === 'dark' && 'text-stone-50',
              ]"
              :source="item?.content || ''"
            ></vue-markdown>
            <div
              v-if="item?.CTA?.length > 0 && item?.imagePosition === 'right'"
              class="flex gap-2 grow"
            >
              <Button
                v-for="button in item?.CTA"
                :key="button?.label"
                :link="button?.url"
                class="self-end w-full"
                @click="handleCTA(button?.url)"
              >
                {{ button?.label }}
              </Button>
            </div>
          </div>
          <div
            v-if="item?.image?.data?.attributes?.url"
            class="shrink-0"
            :class="[
              twMerge(
                {
                  right: 'w-full md:w-1/2',
                  left: 'w-full md:w-1/2',
                  bottom: 'w-full',
                  background: 'background-image',
                }[item?.imagePosition]
              ),
            ]"
          >
            <NuxtImg
              class="rounded-xl object-cover w-full h-full"
              :src="String(item?.image?.data?.attributes?.url)"
            />
          </div>
          <div
            v-if="item?.CTA?.length > 0 && item?.imagePosition === 'bottom'"
            class="flex gap-2 grow"
          >
            <Button
              v-for="button in item?.CTA"
              :key="button?.label"
              :link="button?.url"
              class="self-end w-full"
              @click="handleCTA(button?.url)"
            >
              {{ button?.label }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import VueMarkdown from 'vue-markdown-render'
import { twMerge } from 'tailwind-merge'

const { toggleDateRequestSidebar } = useUiState()
const emit = defineEmits(['openContactForm', 'openCaseStudyForm'])
const { content } = withDefaults(
  defineProps<{
    content: any
  }>(),
  { content: {} }
)
const handleCTA = (action: string) => {
  if (action === 'openEventRequestForm') {
    toggleDateRequestSidebar()
  } else if (action === 'openPartnerContactForm') {
    emit('openContactForm')
  } else if (action === 'openCaseStudyForm') {
    emit('openCaseStudyForm')
  }
}
</script>
<style lang="postcss" scoped>
:deep(.prose) strong {
  color: inherit;
}

.image-position-background {
  position: relative;
  background: none;
  z-index: 1;
  overflow: hidden;
}

.image-position-background::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background: rgba(255, 255, 255, 0.7);
}

.image-position-background.dark::before {
  background: rgba(0, 0, 0, 0.7);
}

.background-image {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -2;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
