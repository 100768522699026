<template>
  <div class="pt-10 pb-6 md:py-20">
    <h2 class="my-2">
      {{ content?.title }}
    </h2>

    <h5 class="mt-4 mb-12">{{ content?.subtitle }}</h5>

    <div class="flex justify-between gap-4 flex-wrap md:flex-nowrap">
      <template v-for="card in content?.cards" :key="card.title">
        <component
          :is="card.link ? NuxtLink : 'div'"
          :to="card.link"
          class="bg-stone-200 select-none w-full px-2 rounded-xl text-center flex flex-col justify-center items-center py-12 transition hover:scale-105"
        >
          <NuxtImg
            v-if="card?.image?.data?.attributes?.url"
            class="w-12"
            :src="String(card?.image?.data?.attributes?.url)"
            :alt="card?.image?.data?.attributes?.alternativeText || card.title"
          />
          <div class="legacy-h3 font-bold mt-8 text-stone-900">
            {{ card.title }}
          </div>
          <p class="mt-2 leading-5">{{ card.description }}</p>
        </component>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { NuxtLink } from '#components'

const { content } = withDefaults(
  defineProps<{
    content: any
  }>(),
  { content: {} }
)
</script>
